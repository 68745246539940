import React from 'react'

const NotFound = () => {
	return (
		<div>
			<pre>404 Page Is Missing</pre>
		</div>
	)
}

export default NotFound
